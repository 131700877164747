import { menuClasses, Menu } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const StyledMyAccountNavWrapper = styled('div', {
  name: 'MyAccountNavWrapper',
})(() => ({
  display: 'flex',
  height: '100%',
}))

export const StyledMenu = styled(Menu, {
  name: 'MyAccountNavMenu',
})(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    borderRadius: 0,
    padding: theme.spacing(12, 24),
    boxShadow: theme.shadows[10],
  },

  [`& .${menuClasses.list}`]: {
    ...theme.typography.body2,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    gap: theme.spacing(4),

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
