import { useTranslation } from 'next-i18next'
import { Button } from '@components/UI/Button'
import { ModalDialog } from '@components/UI/ModalDialog'
import { Typography } from '@mui/material'
import { StyledDialogContainer } from './CartThresholdReachedModal.style'
import CurrencyService from '@services/CurrencyService'
import { useDispatch } from 'react-redux'
import { updateIsCartThresholdReachedFlag } from '@features/order/slice'

interface CartThresholdReachedModal {
  grandTotal: string
  currency: string
  isOpen: boolean
}

const CartThresholdReachedModal: React.FC<CartThresholdReachedModal> = ({ grandTotal, currency, isOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleModalDialogClosing = () => dispatch(updateIsCartThresholdReachedFlag(false))

  return (
    <ModalDialog
      open={isOpen}
      onClose={handleModalDialogClosing}
      onCloseButton={handleModalDialogClosing}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledDialogContainer>
        <Typography variant="h6">{t('Cart.Msgs.CartThresholdReached')}</Typography>
        <Typography variant="body1">
          {CurrencyService.formatValueWithCurrency({
            currency: currency,
            value: grandTotal,
          })}
        </Typography>
        <div>
          <Button fillType="contained" onClick={handleModalDialogClosing}>
            {t('RemoveAddressDialog.YesRemove')}
          </Button>
        </div>
      </StyledDialogContainer>
    </ModalDialog>
  )
}

export { CartThresholdReachedModal }
