import { IContentMegaMenu } from '@typesApp/cms'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'

export const extractLinks = (contentMegaMenu: IContentMegaMenu[], type: string, key: string) => {
  return contentMegaMenu
    .flatMap(item => (item.placements ? item.placements.flatMap(placement => placement.items) : []))
    .filter(item => item.type === type && item[key])
    .flatMap(item => item[key])
    .map(item => ({
      title: (item as ICMExternalChannel).title,
      formattedUrl: (item as ICMExternalChannel).formattedUrl,
    }))
    .filter(item => !!item.formattedUrl)
}

export const extractButtonLinks = (contentMegaMenu: IContentMegaMenu[], type: string, key: string) => {
  const uniqKey = 'title'
  const filteredButtonLinks = contentMegaMenu
    .flatMap(item => (item.placements ? item.placements.flatMap(placement => placement.items) : []))
    .filter(item => item.type === type && item[key])
    .flatMap(item => item[key])
    .map(item => ({
      title: item.callToActionText,
      formattedUrl: item.target?.formattedUrl ?? '#NOT_FOUND',
    }))
    .filter(item => !!item.title)

  return [...new Map(filteredButtonLinks.map(item => [item[uniqKey], item])).values()]
}
