import styled from '@mui/material/styles/styled'

export const StyledDialogContainer = styled('div', {
  name: 'ModalDialog',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
  justifyContent: 'space-around',
  textAlign: 'center',
  padding: theme.spacing(12),
}))
