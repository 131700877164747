import { setCloseDrawerMegaMenu, setOpenDrawerMegaMenu } from '@features/ui/action'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MegaMenuDrawer, MegaMenuItemButton, MegaMenuItemLink, MegaMenuWrapper } from './NavigationMenuDesktop.style'

import { controlTeaserTarget } from '@features/cms/utils'
import { openDrawerMegaMenuSelector, openDrawerSearchSelector } from '@features/ui/selector'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { AppState } from '@redux/store'
import { IContentMegaMenu } from '@typesApp/cms'
import camelCase from 'lodash/camelCase'
import { useRouter } from 'next/router'
import MegaMenuContent from './components/MegaMenuContent'
import { extractButtonLinks, extractLinks } from '../../helpers'
import { useIsSSR } from '@hooks/useIsSSR'
import { useRouterChange } from '@hooks/useRouterChange/useRouterChange'
import useScrollingUp from '@hooks/useScrollingUp/useScrollingUp'
import { replaceLocaleInUrl } from '@utils/locale'
import { locationOriginSelector } from '@redux/selectors/site'

const MemoizedMegaMenuDrawer = memo<{
  id: string
  isOpen: boolean
  contentMegaMenu: IContentMegaMenu[]
  isSticky: boolean
}>(
  ({ id, isOpen, contentMegaMenu, isSticky }) => (
    <MegaMenuDrawer open={isOpen} isSticky={isSticky}>
      {isOpen && <MegaMenuContent content={contentMegaMenu?.find(i => i.id === id)} />}
    </MegaMenuDrawer>
  ),
  (prevProps, nextProps) =>
    prevProps.id === nextProps.id &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.contentMegaMenu === nextProps.contentMegaMenu
)

const NavigationMenuDesktop: React.FC = () => {
  const router = useRouter()
  const header = useSelector((s: AppState) => s.cms.header)
  const isSSR = useIsSSR()
  const megaMenu = useSelector((s: AppState) => s.cms.megaMenu)
  const { id, isOpen } = useSelector(openDrawerMegaMenuSelector)
  const [isMouseOnMenu, setIsMouseOnMenu] = useState<boolean>(false)
  const [selectedMenuItemId, setSelectedMenuItemId] = useState(id)

  const { langCode } = useStoreIdentity()
  const currentRoute = router.pathname.replace(`/${langCode}`, '')
  const menuElements = useRef<HTMLElement[]>([])
  const scrolled = useScrollingUp()
  const [isHeaderVisible, setHeaderVisibility] = useState<boolean>(true)
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)

  useEffect(() => {
    const shouldBeVisible = scrolled === null || isSearchDrawerOpen
    setHeaderVisibility(shouldBeVisible)
  }, [scrolled, isSearchDrawerOpen])

  useEffect(() => {
    if (!megaMenu) return
    const priorityResult = megaMenu.contentMegaMenu?.find(menuItem =>
      menuItem.placements?.some(placement =>
        placement.items.some(item => {
          if (item.type === 'LXTeaser') {
            return item.teaserLXCallToActionSettings.some(cta => cta.target?.formattedUrl === currentRoute)
          }
          return false
        })
      )
    )
    if (priorityResult) {
      setSelectedMenuItemId(priorityResult.id)
      return
    }
    const result = megaMenu.contentMegaMenu?.find(menuItem =>
      menuItem.placements?.some(placement =>
        placement.items.some(item => {
          if ('teasableItems' in item) {
            return item.teasableItems.some(teaser => 'formattedUrl' in teaser && teaser?.formattedUrl === currentRoute)
          }
          if ('teaserLXCallToActionSettings' in item) {
            return item.teaserLXCallToActionSettings.some(cta => cta.target?.formattedUrl === currentRoute)
          }
          if ('brandData' in item) {
            return item.brandData.some(brand => brand?.formattedUrl === currentRoute)
          }
          return false
        })
      )
    )
    if (result) {
      setSelectedMenuItemId(result.id)
    }
  }, [megaMenu, currentRoute])

  const contentMegaMenu = megaMenu?.contentMegaMenu || header?.navigation?.children || []

  return (
    <>
      <MegaMenuWrapper onMouseEnter={() => setIsMouseOnMenu(true)} onMouseLeave={() => setIsMouseOnMenu(false)}>
        {contentMegaMenu?.map((item, index) => (
          <MegaMenuItems
            key={`${item.id} - ${index}`}
            item={item}
            menuElements={menuElements}
            index={index}
            isHighlighted={item.isHighlightedInNav}
            selectedMenuItemId={selectedMenuItemId}
            setSelectedMenuItemId={setSelectedMenuItemId}
          />
        ))}
      </MegaMenuWrapper>
      {isSSR ? (
        <MegaMenuSSR contentMegaMenu={contentMegaMenu} />
      ) : (
        <MemoizedMegaMenuDrawer id={id} isOpen={isOpen} isSticky={isHeaderVisible} contentMegaMenu={contentMegaMenu} />
      )}
    </>
  )
}

const MegaMenuItems: React.FC<{
  item: IContentMegaMenu
  menuElements: any
  index: number
  selectedMenuItemId: string
  isHighlighted: boolean
  setSelectedMenuItemId: (id: string) => void
}> = ({ item, index, menuElements, selectedMenuItemId, setSelectedMenuItemId, isHighlighted }) => {
  const dispatch = useDispatch()
  const { isRouterChange } = useRouterChange()
  const { basePath } = useStoreIdentity()
  const { id, isOpen } = useSelector(openDrawerMegaMenuSelector)
  const openDrawerMegaMenu = (selectId: string, selectCategory?: string) => {
    if (id === selectId) {
      dispatch(setCloseDrawerMegaMenu())
      return
    }
    dispatch(setOpenDrawerMegaMenu(selectId, selectCategory || ''))
    setSelectedMenuItemId(selectId || item.title)
  }

  useEffect(() => {
    if (item.id && item.title === selectedMenuItemId) {
      openDrawerMegaMenu(item.id, item.name)
    }
  }, [item.id, selectedMenuItemId])

  const isExternal = ['CMExternalLink', 'CMTeaser'].includes(item.type)
  const href = item.teaserTitle
    ? `${basePath}${item.formattedUrl.replace('/c/', '/info/')}`
    : `${basePath}${item.formattedUrl}`
  const link = isExternal
    ? item.type === 'CMTeaser'
      ? controlTeaserTarget(item?.teaserTargets || [])
      : item.formattedUrl
    : href

  useEffect(() => {
    dispatch(setCloseDrawerMegaMenu())
  }, [isRouterChange])

  return item.type === 'CMExternalPage' ? (
    <MegaMenuItemButton
      key={item.id}
      data-element-id={`MainNav_${item.title?.toUpperCase()}`}
      data-name={camelCase(item.name)}
      active={isOpen && item.id === selectedMenuItemId}
      disableRipple={true}
      disableTouchRipple={true}
      onClick={() => openDrawerMegaMenu(item.id, item.name)}
      isHighlighted={item.isHighlightedInNav}
      ref={item => (menuElements.current[index] = item)}
    >
      {item.title}
    </MegaMenuItemButton>
  ) : (
    <MegaMenuItemLink
      key={item.id}
      data-element-id={`MainNav_${item.title?.toUpperCase()}`}
      data-name={camelCase(item.name)}
      active={item.id === selectedMenuItemId}
      href={link}
      isHighlighted={item.isHighlightedInNav}
    >
      <span ref={item => (menuElements.current[index] = item)}>{item.title}</span>
    </MegaMenuItemLink>
  )
}

const MegaMenuSSR: React.FC<{
  contentMegaMenu?: IContentMegaMenu[]
}> = ({ contentMegaMenu }) => {
  const router = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  if (!contentMegaMenu) return null

  const CMExtenalChannelLinks = extractLinks(contentMegaMenu, 'CMCollection', 'teasableItems')

  const CMCButtonLinks = extractButtonLinks(contentMegaMenu, 'CMCollection', 'teaserLXCallToActionSettings')
  const LXTeaserLinks = extractButtonLinks(contentMegaMenu, 'LXTeaser', 'teaserLXCallToActionSettings')

  return (
    <div className="visually-hidden">
      {CMExtenalChannelLinks.concat(CMCButtonLinks)
        .concat(LXTeaserLinks)
        .map((item, index) => (
          <a
            key={item.title + '_' + index}
            href={replaceLocaleInUrl({
              href: item.formattedUrl,
              isLocaleDomain: router.isLocaleDomain,
              locale: router.locale,
              locationOrigin,
            })}
            aria-label={item.title || item.formattedUrl}
          >
            {item.title || item.formattedUrl}
          </a>
        ))}
    </div>
  )
}

export default NavigationMenuDesktop
