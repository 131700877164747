import styled from '@mui/material/styles/styled'
import ButtonBase from '@mui/material/ButtonBase'
import { Z_INDEX_LV3 } from '../../../../../../constants/ui'
import { StyledAnchor } from '@components/UI/Anchor'

export const MegaMenuWrapper = styled('div', {
  name: 'NavigationMenuDesktop',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',

    button: {
      padding: theme.spacing(5, 16),

      '&:first-of-type': {
        paddingLeft: 0,
      },
    },
  },
}))

const MegaMenuButtonBase = ({ theme, isHighlighted }) => ({
  ...theme.typography.body2,
  color: isHighlighted ? theme.palette.error.main : theme.palette.common.white,
  ...(isHighlighted && {
    backgroundColor: theme.palette.background[10],
    color: theme.palette.text.primary,
    textDecoration: 'none',

    span: {
      fontWeight: 700,
    },
  }),
})

export const MegaMenuItemButton = styled(ButtonBase, {
  name: 'NavigationMenuDesktop',
  slot: 'ItemButton',
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isHighlighted',
})<{ active: boolean; isHighlighted: boolean }>(MegaMenuButtonBase)

export const MegaMenuItemLink = styled(StyledAnchor, {
  name: 'NavigationMenuDesktop',
  slot: 'ItemLink',
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isHighlighted',
})<{ active: boolean; isHighlighted: boolean }>(({ theme, isHighlighted }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  ...MegaMenuButtonBase({ theme, isHighlighted }),

  '&:first-of-type > span': {
    padding: theme.spacing(5, 16),
  },
}))

export const MegaMenuDrawer = styled('div', {
  name: 'NavigationMenuDesktop',
  slot: 'Drawer',
  shouldForwardProp: prop => prop !== 'open' && prop !== 'isSticky',
})<{ open: boolean; isSticky: boolean }>(({ open, isSticky, theme }) => ({
  position: 'absolute',
  background: theme.palette.common.white,
  top: isSticky ? 134 : 41,
  left: 0,
  right: 0,
  zIndex: Z_INDEX_LV3,
  height: open ? `calc(100vh - ${isSticky ? '134px' : '41px'})` : 0,
  visibility: open ? 'visible' : 'hidden',
  transition: 'all 0.3s ease 0s',

  [theme.breakpoints.up('md')]: {
    transition: 'none',
  },
}))
