import React, { useState } from 'react'
import { StyledMenu, StyledMyAccountNavWrapper } from './MyAccountNavigation.style'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { logout } from '@features/user/thunks/logout'
import { userShortNameSelector } from '@features/user/selector'
import { useRouter } from 'next/router'
import { ACCOUNT, ACCOUNT_CHILDREN, SIGNIN } from '@constants/routes'
import MenuItem from '@components/UI/MenuItem'
import { getInsuranceEventModule } from '@components/DirectBilling/utils/insuranceModule'
import Axios, { Canceler } from 'axios'
import { SlideHoverBtn } from '../../NavigationBar.style'
import { locationOriginSelector, isRememberMeEnabledSelector } from '@redux/selectors/site'
import { replaceLocaleInUrl } from '@utils/locale'

function MyAccountNavigation() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { locale, isLocaleDomain, push: routerPush } = useRouter()
  const loggedInUserName = useSelector(userShortNameSelector)
  const [anchorEl, setAnchorEl] = useState(null)
  const locationOrigin = useSelector(locationOriginSelector)
  const isRememberMeEnabled = useSelector(isRememberMeEnabledSelector)

  const CancelToken = Axios.CancelToken
  const cancels: Canceler[] = []

  const payloadBase: unknown = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
    ...(isRememberMeEnabled && { rememberMe: false, updateCookies: true }),
  }

  const logOutUser = () => {
    const { interceptLogout } = getInsuranceEventModule()

    const callback = () => {
      const payload = payloadBase ?? {}
      handleClose()
      dispatch(logout(payload))
    }

    interceptLogout(callback)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnItemClick = route => {
    handleClose()
    if (route) {
      routerPush(replaceLocaleInUrl({ isLocaleDomain, locationOrigin, locale, href: route }) as string)
    }
  }

  const handleRedirectToSignIn = () => {
    routerPush(replaceLocaleInUrl({ isLocaleDomain, locationOrigin, locale, href: `/${SIGNIN}` }) as string)
  }

  const generateNavItem = () => {
    const btnLabel = loggedInUserName ? loggedInUserName : t('Header.Actions.SignIn')
    const btnProps = {
      id: 'navigation-my-account-cta',
      dataname: 'myAccountCta',
      disableRipple: true,
      onClick: loggedInUserName ? handleClick : handleRedirectToSignIn,
    }

    return (
      <SlideHoverBtn isActive={Boolean(anchorEl)} data-element-id="MainNav_MyAccount" {...btnProps}>
        {btnLabel}
      </SlideHoverBtn>
    )
  }

  const menuItems = [
    {
      id: 'my-account',
      label: t('Header.Actions.Account'),
      onClick: () => handleOnItemClick(`/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`),
      dataName: 'menuMyaccount',
    },
    {
      id: 'reorder-three-clicks',
      label: t('Header.Actions.ReorderInThreeClicks'),
      onClick: () => handleOnItemClick(`/${ACCOUNT}/${ACCOUNT_CHILDREN.ORDER_HISTORY}`),
      dataName: 'menuReorderIn3Clicks',
    },
    {
      id: 'order-history',
      label: t('Header.Actions.ViewOrders'),
      onClick: () => handleOnItemClick(`/${ACCOUNT}/${ACCOUNT_CHILDREN.ORDER_HISTORY}`),
      dataName: 'menuViewOrders',
    },
    {
      id: 'logout',
      label: t('Header.Actions.Logout'),
      onClick: () => logOutUser(),
      dataName: 'menuLogOut',
    },
  ]

  return (
    <StyledMyAccountNavWrapper>
      {generateNavItem()}
      <StyledMenu
        id="navigation-my-account-popup"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {menuItems.map(item => (
          <MenuItem key={item.id} onClick={item.onClick} data-name={item.dataName}>
            {item.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </StyledMyAccountNavWrapper>
  )
}

export default MyAccountNavigation
